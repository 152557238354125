import React, { useState } from 'react';
import CourseImg from '../../assets/images/Course/Courseimage.png';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import './courseDashboard.css';
//import Saved from '../../assets/images/Course/CourseSaved.png';
import DeleteCorse from '../../assets/images/DeleteCourse.svg'
import updatecourse from '../../assets/images/editcourse.svg'
const CourseDashboard = () => {
  // State to manage archiving status
  const [isArchived, setIsArchived] = useState(false);

  // Function to toggle archive status
  const toggleArchive = () => {
    setIsArchived(!isArchived);
  };

  return (
    <div className={`${!isArchived ? 'Course_Container' : 'Unarchived'}`}>
      <div className='Course_Sec1'>
        <img className='Course_Sec1' src={CourseImg} alt='CourseImg' />
      </div>
      {/* Sec2 Course Body */}
      <div className='Course_Sec2'>
        Start 11 October
      </div>
      <div className='Course_Sec3'>
        Psychology of Conspiracy Theories
      </div>
      <div className='Course_Sec4'>
        <img src={Clock} alt='Clock'/> 6 hours &nbsp; (11 sessions)
      </div>
      <div className='Course_Sec5'>
        <img src={Chair} alt='Chair'/> 30 seats left 
        <img src={Line} alt='Line' />
        <img src={Start} alt='Start' /> 95%(234)
      </div>
      <div className='Course_Sec6'>
        .......................................................
      </div>
      <div className='Course_Sec7'>
        <button 
          className='Course_Dashboard_Archive' 
          onClick={toggleArchive}
        >
          {isArchived ? 'UnArchive' : 'Archive'}
        </button>
        <div className='course_icon_modify'>
            <img src={updatecourse} alt='updateCourse' />
            <img src={DeleteCorse} alt='deleteCourse' />
        </div>
  
      </div>
    </div>
  );
}

export default CourseDashboard;
