import React from 'react'
import './InstructorNav.css'
import LndexLogo from '../../assets/images/lndexlogo_svg.svg' 
import NotificationIcon from '../../assets/images/Notfication.svg'
import WorldLanguage from '../../assets/images/worldLanguage.svg'
const InstructorNav = () => {
  return (
    <div className='InstructorNav_Container'>
          {/* The LeftSide Logo  */}
         <div className='InstructorNav_Logo'>
             <img src={LndexLogo} alt='logo' />
         </div>
          {/* The Right Side  */}
          <div className='InstructorNav_RightSide'>
                 <div> <img src={NotificationIcon} alt='notficationIcon'/></div>
                 <div><button className='InstructorNav_LanguageButton'><img src={WorldLanguage} alt='langIcon'/>العربيه</button></div>
                 <div><button className='InstructorNav_LogoutButton'>Log Out</button></div>
          </div>
        
    </div>
  )
}
export default InstructorNav