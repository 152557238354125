import React from 'react';
import BeginnerIcon from '../../../assets/images/Course/add_beginner.svg';
import IntermediateIcon from '../../../assets/images/Course/add_intermediate.svg';
import AdvancedIcon from '../../../assets/images/Course/add_advanced.svg';

const CourseLevelSelector = ({ selectedLevel, onLevelChange }) => {
  const levels = [
    { id: 'beginner', label: 'Beginner', image: BeginnerIcon },
    { id: 'intermediate', label: 'Intermediate', image: IntermediateIcon },
    { id: 'advanced', label: 'Advanced', image: AdvancedIcon },
  ];

  return (
    <div className="course-level-buttons">
      {levels.map((level) => (
        <button
          key={level.id}
          type="button"  // Prevent form submission when selecting a button
          className={`level-button ${selectedLevel === level.id ? 'selected' : ''}`}
          onClick={() => onLevelChange(level.id)}
        >
          <div className='CourseLevelSelector_Button_content'>
            <img src={level.image} alt={`${level.label} icon`} />
            {level.label}
          </div>
        </button>
      ))}
    </div>
  );
};

export default CourseLevelSelector;

