import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';

// Async thunk لإرسال طلب تسجيل الدخول للمدرس
export const instructorLogin = createAsyncThunk(
  'instructor/login', 
  async ({ phone, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/instructor/login`, { phone, password });
      console.log(response.data); // للتحقق من البيانات المستلمة
      console.log(response.data.data.instructor.token); // للتحقق من وجود الـ token
      return response.data.data.instructor.token; // إعادة التوكن عند النجاح
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : 'Login failed'); // إرسال الخطأ في حالة الفشل
    }
  }
);

// Slice لحالة تسجيل الدخول
const instructorLoginSlice = createSlice({
  name: 'instructorLogin',
  initialState: {
    token: null,  // توكن تسجيل الدخول
    loading: false, // حالة التحميل
    error: null, // الأخطاء
  },
  reducers: {
    logout: (state) => {
      state.token = null;
      localStorage.removeItem('Instructor_Token'); // إزالة التوكن عند تسجيل الخروج
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(instructorLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(instructorLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload; // حفظ التوكن في الحالة
        localStorage.setItem('Instructor_Token', action.payload); // تخزين التوكن في localStorage
      })
      .addCase(instructorLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // حفظ الخطأ في الحالة
      });
  },
});

export const { logout } = instructorLoginSlice.actions;
export default instructorLoginSlice.reducer;
