import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '../../../assets/images/instructors/add.svg';
import CourseDashboard from '../../../component/courseDashboard/courseDashboard';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const InstructorCourses = () => {
  return (
    <div className="container-fluid">
      <div className='row mb-3'>
        <div className="col-lg-12 d-flex justify-content-end">
          <Link to='/createcourse' className='link'>
            <button className='InstructorHome_AddCourse_Button'>
              <img src={AddIcon} alt='AddTheIcon' className='me-2' />
              Create New Course
            </button>
          </Link>
        </div>
      </div>

      <div className='row'>
        {/* Iterate through the courses array to render CourseDashboard components dynamically */}
        {[...Array(9)].map((_, index) => (
          <div key={index} className='col-xxl-3 col-lg-4 col-md-6 col-sm-6 mb-3'>
            <CourseDashboard />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstructorCourses;

