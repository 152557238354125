import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'; 
import Spinner from 'react-bootstrap/Spinner';
//import { instructorLogin } from '../../features/instructorLoginSlice'; // استيراد thunk لتسجيل الدخول
import { instructorLogin } from '../../store/features/instructorLoginSlice'; // Correct import

const InstructorLogin = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
 
  const dispatch = useDispatch(); // استخدام dispatch لإرسال إجراءات Redux
  const navigate = useNavigate();

  const { loading, error, token } = useSelector((state) => state.instructorLogin); // استدعاء الحالة من Redux

  const handlePhone = (e) => setPhone(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleInstructorSubmit = () => {
    dispatch(instructorLogin({ phone, password }))
      .then((action) => {
        if (instructorLogin.fulfilled.match(action)) {
          navigate('/InstructorHome');
        } else {
          console.error('Login failed:', action.payload);
        }
      });
  };

  return (
    <div className='Signup_body_input_container'>
      <div className='Signup_body_Form_Sec4'>
        <div>Phone</div>
        <div className='Signup_body_input'>
          <input
            className='Sign_input'
            type="text"
            placeholder='+123456789'
            value={phone}
            onChange={handlePhone}
          />
        </div>
      </div>

      <div className='Signup_body_Form_Sec4'>
        <div>Password</div>
        <div className='Signup_body_input'>
          <input
            className='Sign_input'
            type="password"
            placeholder='Password'
            value={password}
            onChange={handlePassword}
          />
        </div>
      </div>

      <div className='Error_message'>
        {error && <div><p>{error}</p></div>}
      </div>

      <div className='Signup_body_Form_Sec5' style={{display:'flex',flexDirection:'row-reverse'}}>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <button className='Signup_body_Form_Sec5_button' onClick={handleInstructorSubmit}>
            Login
          </button>
        )}

        <div style={{ display: 'flex', flexDirection: "column-reverse", alignItems: 'flex-start', gap: '10px' }}>
          <Link to='/forget-password'>
            <div style={{ cursor: 'pointer' }}>Forget Password?</div>
          </Link>
          <div>Don’t have an account? <span>Sign Up</span></div>
        </div>
      </div>
    </div>
  );
};

export default InstructorLogin;
