import React,{ useState } from 'react'
import ArticleImg from '../../assets/images/Article.png'
import AuthorImage from '../../assets/images/articler.png'
import DeleteCorse from '../../assets/images/DeleteCourse.svg'
import updatecourse from '../../assets/images/editcourse.svg'
const ArticleDashboard = () => {
      // State to manage archiving status
  const [isArchived, setIsArchived] = useState(false);

  // Function to toggle archive status
  const toggleArchive = () => {
    setIsArchived(!isArchived);
  };
  return (
    <div className={`${!isArchived ? 'Article_container' : 'Unarchived'}`} >
         <div className='Article_Sec1'>
              <img className='Article_Sec1' src={ArticleImg} alt='ArticleImg'></img>
         </div>
         <div className='Article_Sec2'>
                5-10 mins to read
         </div>
         <div className='Article_Sec3'>
         Your target audience. Who are you writing for?
         </div>
         <div className='Article_Sec4'>
         Massa diam risus cursus vestibulum mi. Quam sed habitant aliquam pellentesque lacus gravida neque interdum at.
         </div>
         <div className='Article_Sec5'>
         <div className='Author_information'>
            <div className='Article_Sec5_Part1'>
              <img className='author_icon' src={AuthorImage} alt='AuthorImage'></img>
            </div>
            <div className='Article_Sec5_Part2'>
                 <div className='Article_Sec5_Part2_name'>Zeinab Sayed </div>
                 <div className='Article_Sec5_Part2_date'>17 October 2023</div>
             </div>
         </div>
         </div>
         <div className='Course_Sec7'>
        <button 
          className='Course_Dashboard_Archive' 
          onClick={toggleArchive}
        >
          {isArchived ? 'UnArchive' : 'Archive'}
        </button>
        <div className='course_icon_modify'>
            <img src={updatecourse} alt='updateCourse' />
            <img src={DeleteCorse} alt='deleteCourse' />
        </div>
  
      </div>
    </div>
  )
}

export default ArticleDashboard