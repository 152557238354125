import React from "react";
import PlusIcon from '../../../assets/images/ei_plus.svg'
import MinusIcon from '../../../assets/images/ei_minus.svg'
const TimeSessionSelector = ({ hours, setHours, minutes, setMinutes, sessions, setSessions }) => {

  // Increment and Decrement functions remain the same
  const handleIncrement = (setter, value, limit = Infinity) => {
    if (value < limit) setter(value + 1);
  };

  const handleDecrement = (setter, value, min = 0) => {
    if (value > min) setter(value - 1);
  };

  // New: Prevent form submission for each button click
  const preventSubmit = (event) => {
    event.preventDefault(); // Prevents the button from triggering form submission
  };

  return (
    <div className="time-session-selector">
      {/* Hours */}
      <div className="time-block">
        <p className="time-block-title">Hours</p>
        <div className="Duration-Input_Countainer">
          <img src={MinusIcon} alt="MinusIcon" onClick={(e) => { preventSubmit(e); handleDecrement(setHours, hours); }}/>  
          <input type="text" value={hours}  />
          <img src={PlusIcon} alt="PlusIcon"  onClick={(e) => { preventSubmit(e); handleIncrement(setHours, hours); }} />
        
        </div>
      </div>

      {/* Minutes */}
      <div className="time-block">
        <p className="time-block-title">Minutes</p>
        <div className="Duration-Input_Countainer">
          <img src={MinusIcon} alt="MinusIcon" onClick={(e) => { preventSubmit(e); handleDecrement(setMinutes, minutes); }}/>  
          <input type="text" value={minutes} readOnly />
          <img src={PlusIcon} alt="PlusIcon" onClick={(e) => { preventSubmit(e); handleIncrement(setMinutes, minutes, 59); }}/>
        
        </div>
      </div>

      {/* Sessions */}
      <div className="time-block">
        <p className="time-block-title">Sessions</p>
        <div className="Duration-Input_Countainer">
          <img src={MinusIcon} alt="MinusIcon" onClick={(e) => { preventSubmit(e); handleDecrement(setSessions, sessions); }}/>
          <input type="text" value={sessions} readOnly />
          <img src={PlusIcon} alt="PlusIcon" onClick={(e) => { preventSubmit(e); handleIncrement(setSessions, sessions); }}/>
          
        </div>
      </div>
    </div>
  );
};

export default TimeSessionSelector;
