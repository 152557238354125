import React , { useState , useRef } from 'react'
import './instructorcreateCourse.css'
import LineDashboard from '../../../assets/images/instructors/LineDashboard.svg'
import DateSelector from './DateSelector';
import CourseLevelSelector from './CourseLevelSelector';
import VolumeIcon from '../../../assets/images/Course/VolumeLevel.svg'
import ExitIcon from '../../../assets/images/instructors/exitIcon.svg'
import UploadIcon from '../../../assets/images/lmg_upload.svg'
import TimeSessionSelector from './TimeSessionSelector';
const InstructorCreateCourse = () => {
   
    const [selectedLevel, setSelectedLevel] = useState('');
    const [selectedLang, setSelectedLang] = useState(''); // Initialize with empty or default language
    const [qna, setQna] = useState([{ question: "", answer: "" }]);
    // Course Image 
    const [selectedFile, setSelectedFile] = useState(null);
    console.log(selectedFile)
    const fileInputRef = useRef(null);
    const [preview, setPreview] = useState(null);
    console.log(selectedLevel)
    const [answer, setAnswer] = useState(null); // null by default, true for Yes, false for No
    // Time | Duration
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(30);
    const [sessions, setSessions] = useState(3);
    const handleYes = (e) => {
        e.preventDefault()
        setAnswer(true); // Sets to true when Yes is clicked
       // console.log("yes")
      };
    
      const handleNo = (e) => {
        e.preventDefault()
        setAnswer(false); // Sets to false when No is clicked
       // console.log("no")
      };
        // add question and answer section handle functios 
        const addNewQna = () => {
          setQna([...qna, { question: "", answer: "" }]);
        };
        // Function to handle removing a specific question and answer
  const removeQna = (index) => {
    const updatedQna = qna.filter((_, idx) => idx !== index);
    setQna(updatedQna);
      };
        const handleLangChange = (lang) => {
          setSelectedLang(lang); // Update the selected language when a button is clicked
        };
         
         // Function to handle input changes
         const handleChange = (index, field, value) => {
         const updatedQna = qna.map((item, idx) =>
         idx === index ? { ...item, [field]: value } : item
    );
    setQna(updatedQna);
      };
          // function to select Course_Image
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
          console.error('No file selected');
          return;
        }
        setSelectedFile(file);
    
        // Preview the image
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          setPreview(fileReader.result);
        };
        fileReader.readAsDataURL(file);
      };
      // image click 
      const handleImageClick = () => {
        // Trigger the file input click when image is clicked
        fileInputRef.current.click();
      };
  return (
    <div>
        <div className='InstructorCreateCourse_Container'>
                        {/* The First Section and Publish Button */}
                  <div className='InstructorCreateCourse_Sec1'>
                       <div>Fill the info bellow to add new course</div>
                      
                  </div>      
                         {/* Start Form  */}
                    <div className='Dashboard_FormContainer'>  
                    <form className='InstructorCreateCourse_form'>
                    <div><img src={LineDashboard} alt='linedashboard'/></div>
                        <div className='InstructorCreateCourse_form_Section'>
                           <div className='InstructorCreateCourse_form_Section_title'>Write title of your course*</div>                          
                           <input type='text' className='InstructorCreateCourse_form_Section_input'/>
                        </div>   
                <div><img src={LineDashboard} alt='linedashboard'/></div>
                        <div className='InstructorCreateCourse_form_Section'>
                             <div className='InstructorCreateCourse_form_Section_title'>Paste URL Link of your Promo Video for your Course(Youtube/vimeo)*</div>                          
                             <textarea  rows="5"  cols="50" className='InstructorCreateCourse_form_Section_input' style={{height:'150px'}}>
                                At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
                             </textarea>
                        </div>   
                <div><img src={LineDashboard} alt='linedashboard'/></div>
                         {/* Yes or No Question to Select  */}
                         <div className='InstructorCreateCourse_form_Section_title'>
        Will this course be certified?
      </div>

      <div className='InstructorCreateCourse_form_Section_True_False'>
        {answer === null ? (
          // Show buttons if no option is selected yet
          <>
            <div>
              <button 
                onClick={handleYes} 
                className='InstructorCreateCourse_form_button_yes'>
                Yes
              </button>
            </div>
            <div>
              <button 
                onClick={handleNo} 
                className='InstructorCreateCourse_form_button_no'>
                No
              </button>
            </div>
          </>
        ) : (
          // Show message after selection
          <div className='InstructorCreateCourse_form_Message'>
            {answer ? (
              <p style={{background:'green',padding:'10px',color:'#fff',fontSize:'18px',borderRadius:'8px'}}>Great choice! Your course will be certified, which adds more credibility for students.</p>
            ) : (
              <p style={{background:'tomato',padding:'10px',color:'#fff',fontSize:'18px',borderRadius:'8px'}}>You've chosen to keep the course uncertified. You can always change this later.</p>
            )}
          </div>
        )}
      </div>
                 <div><img src={LineDashboard} alt='linedashboard'/></div>
                    <div className='InstructorCreateCourse_form_Section'>
                      <DateSelector />
                    </div>
                    <div><img src={LineDashboard} alt='linedashboard'/></div>
                     {/* Select Level  */}

                     <div className='InstructorCreateCourse_form_Section'>
                     <div className='InstructorCreateCourse_form_Section_title'>Course Level*</div> 
                     <CourseLevelSelector 
                        selectedLevel={selectedLevel} 
                        onLevelChange={setSelectedLevel} 
                     />   
                     </div>
                      {/*  Language  */}
                      <div><img src={LineDashboard} alt='linedashboard'/></div>
                      <div className='InstructorCreateCourse_form_Section'>
                     <div className='InstructorCreateCourse_form_Section_title'>Course Language*</div> 
                     <div className="InstructorCreateCourse_form_Language">
                            <button
                              className={`level-button ${selectedLang === 'Arabic' ? 'selected' : ''}`}
                              onClick={() => handleLangChange('Arabic')} // Set Arabic as selected
                              type="button"  // Prevent form submission when selecting a button
                               >
                                <img src={VolumeIcon} alt='TheSoundIcon' />
                                Arabic
                            </button>

                            <button
                              className={`level-button ${selectedLang === 'English' ? 'selected' : ''}`}
                              onClick={() => handleLangChange('English')} // Set English as selected
                              type="button"  // Prevent form submission when selecting a button
                             >
                               <img src={VolumeIcon} alt='TheSoundIcon' />
                               English
                            </button>
                         </div>
                       </div>
                       <div><img src={LineDashboard} alt='linedashboard'/></div>

                        <div className='InstructorCreateCourse_form_Section'>
                                <div className='InstructorCreateCourse_form_Section_title'>Duration*</div> 
                                <TimeSessionSelector 
                                 hours={hours}
                                 setHours={setHours}
                                 minutes={minutes}
                                 setMinutes={setMinutes}
                                 sessions={sessions}
                                 setSessions={setSessions}
                                
                                />

                        </div>
                       <div><img src={LineDashboard} alt='linedashboard'/></div>
                       <div className='InstructorCreateCourse_form_Section'>
                     <div className='InstructorCreateCourse_form_Section_title'>Course Price*</div> 
                     <input type='text' className='InstructorCreateCourse_form_Section_input'/>
                     </div>
                     <div><img src={LineDashboard} alt='linedashboard'/></div>
                     <div className='InstructorCreateCourse_form_Section'>
                          <div className='InstructorCreateCourse_form_Section_title'>Add Deception for your Course*</div> 
                          <textarea  rows="8"  cols="50" className='InstructorCreateCourse_form_Section_input' style={{height:'150px'}}>
                                At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
                          </textarea>
                     </div>
                     <div><img src={LineDashboard} alt='linedashboard'/></div>
                     <div className='InstructorCreateCourse_form_question_container'>
                     <div className='InstructorCreateCourse_form_Section_title'>FAQs about this course</div>
                     <button type="button" onClick={addNewQna} className='InstructorCreateCourse_Sec1_publish_Button'>
                       Add New Question & Answer
                     </button>
                     </div>
                     {/* FAQs about this course */}
                     {qna.map((item, index) => (
        <div key={index} className="qna-container" >
          <div style={{display:'flex',position:'relative',alignItems:'center'}}>
          <input
            type="text"
            placeholder="tap to write answer"
            value={item.question}
            className='InstructorCreateCourse_form_Section_input'
            onChange={(e) =>
              handleChange(index, "question", e.target.value)
            }
          />
              {/* X Icon to remove the question and answer */}
              <div style={{position:'absolute',right:'2%'}}>
                <img src={ExitIcon} alt='exiticon' onClick={() => removeQna(index)} style={{cursor:'pointer'}}/>
              </div>
          </div>  
            {/* end about input and button exit */}
          <textarea
            placeholder="Enter answer"
            value={item.answer}
            style={{height:'150px'}}
            className='InstructorCreateCourse_form_Section_input'
            onChange={(e) =>
              handleChange(index, "answer", e.target.value)
            }
          />
      
        </div>
      ))}
         {/* Number oF Sets */}
       <div><img src={LineDashboard} alt='linedashboard'/></div>
       <div className='InstructorCreateCourse_form_Section'>
                           <div className='InstructorCreateCourse_form_Section_title'>Write The Number Of Sets</div>                          
                           <input type='text' className='InstructorCreateCourse_form_Section_input'/>
                        </div>   
                        {/* Number oF Sets */}
                        <div><img src={LineDashboard} alt='linedashboard'/></div>
                         <div className='InstructorCreateCourse_form_Section'>
                           <div className='InstructorCreateCourse_form_Section_title'>Upload cover photo for your Course*</div>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',gap:'200px'}}>   
                        <div onClick={handleImageClick} style={{ cursor: 'pointer' ,display:'flex',flexDirection:'column',alignItems:'center' }}>
                            <img src={UploadIcon} alt="imageupload" style={{ width: '50px', height: '50px' }} />
                            <div >Upload Photo</div>
                        </div>
                        {/* Hidden file input */}
                         <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }} // Hide the file input
                          onChange={handleFileChange}
                         />
                         {/* Display file name if selected */}
                         <div style={{display:'flex',flexDirection:'column-reverse',alignItems:'center',gap:'8px'}}>
                         {selectedFile ? <p>Selected file: {selectedFile.name}</p> :''}
                        {preview ||  selectedFile ? <img src={preview} alt="Image preview" style={{ maxWidth:'200px',position:'relative',maxHeight:'250px' }} /> : ''}
                        </div>
                        </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end',width:'100%',marginTop:'50px'}}><button className='InstructorCreateCourse_Sec1_publish_Button'>Publish</button></div>
                    </form>    
                    </div>   
        </div>
    </div>
  )
}
export default InstructorCreateCourse