import React , { useState } from 'react'
import './instructorHome.css'
import InstructorImage from '../../../assets/images/instructors/instructor1.png'
import EditInstructorIcon from '../../../assets/images/instructors/Icon-Edit.svg'

import Line from '../../../assets/images/Line.svg'
import InstructorCourses from './InstructorCourses'
import { Link } from 'react-router-dom'
import ArticleDashboard from '../../../component/ArticleDashboard/ArticleDashboard'
import InstructorArticles from './instructorArticles'
const InstructorHome = () => {
   // To active Section 4 Item 
   const [activeSection , setActiveSection]= useState('Courses')
   const renderSection = () => {
    switch (activeSection) {
      case 'Review':
        return '<InstructorReview />';
      case 'Courses':
        return   <InstructorCourses /> ;
      
      case 'Articles':
        return <InstructorArticles/>;
      case 'About':
        return '<InstructorAbout />';
      default:
        return null;
    }
  };
  return (
      
    <div className='InstructorHome_Container'>
              {/* all page divided as Sections*/}  
              {/* Section One "Welcome Instructor" */}  
         <div className='InstructorHome_Section1'> 
            Welocme Back Muhammed!
            <div className='InstructorPage_Sec1_Part1' style={{}}></div>
         </div>
        
           {/* Section Two select from instructor Profile  "Welcome Instructor" */}  

          <div className='InstructorHome_Sec2_Container'>
           <div style={{display:'flex',alignItems:'center',gap:'32px'}}>
         <div className='InstructorPage_Sec2_Part1'>
                        <img className='InstructorImage' src={InstructorImage} alt='InstructorImage'></img>
                      </div>
                      <div className='InstructorPage_Sec2_part2'>
                             <div>
                                 <div className='InstructorPage_Sec2_part2_name'>Muhammed ibrahim</div>
                                 <div className='InstructorPage_Sec2_part2_Major'>art director</div>
                                 <div className='InstructorPage_Sec2_part2_JoinDate'>Joined June 2021</div>
                             </div>
                             <button className='InstructorPage_Sec2_part2_EditInfo'><img src={EditInstructorIcon} alt='editIcon'/> Edit My Info</button>
                     </div>
                     </div>
                                 {/* Section 2 Right Side  */}
                              <div>dd</div>   
                     </div> 
                       {/* Section 3 and 4  */}
                        {/*  Start Section 4 */}
                      <div className='InstructorHomePage_Sec4'>
                      <div className={activeSection === 'Courses' ? 'active' : '' }
                      onClick={() => setActiveSection('Courses')}
                      >Courses</div>
                      <div className={activeSection === 'Articles'? 'active' : '' }
                      onClick={() => setActiveSection('Articles')}
                      >Articles</div>
                      <div className={activeSection === 'About' ? 'active' : '' }
                      onClick={() => setActiveSection('About')}
                      >About</div>
                      <div className={activeSection === 'Review'? 'active' : '' }
                      onClick={() => setActiveSection('Review')}
                      >Review</div>
             </div>
             <div className='Line_instractor_Dashboard'><img src={Line} alt='Line'/></div>
     
             <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'50px'}}>  
               {renderSection()}
             </div>
  
    </div>
  )
}
export default InstructorHome