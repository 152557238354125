import './App.css';
import Blog from './pages/Blog/index';
import Home from './pages/Home/index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IndexInstructor from './pages/InstructorPage/index';
import CartPage from './pages/Cart/index';
import SearchPage from './pages/SearchPage/index';
import CourseDetails from './pages/CourseDetails/index';
import ArticleById from './pages/ArticleById/index';
//import Sidebar from './component/Sidebar';
//import UnauthAppbar from './component/AppbarUnAuth/UnauthAppbar';
//import Signup from './component/Signup/Signup';
import LoginPage from './pages/LoginPage/index';
import SignupPage from './pages/SignupPage/index';
import PrivacyPolicy from './pages/PrivacyPolicy/index';
import UserProfile from './pages/UserProfile/index';
import UserHome from './pages/UserHome/index';
import UpdateProfile from './pages/UpdateProfile/index';
import Forgetpassword from './pages/ForgetPassword/index';
import VerfiyCode from './pages/VerfiyCode/index'
import ChangePassword from './pages/ChangePassword/index';
import Bootstrap from './component/Bootstrap/Bootstrap'
import UserCart from './pages/userPages/UserCart/index';
import UserBlog from './pages/userPages/UserBlog/index';
import Testform from './pages/Testform/TestForm'
import SavedCourses from './pages/SavedCourses/index';
import InstructorHome from './pages/InstructorAsUser/InstrectorHome/index';
import InstructorCreateCourse from './pages/InstructorAsUser/InstructorDashboard/index';
function App() {
  const storedData = JSON.parse(localStorage.getItem("user_data"));
  const ProfileSlug = storedData ? `${storedData.first_name}${storedData.last_name}` : '';
     console.log(ProfileSlug)
  return (
    <Router>
    <Routes>
      <Route path="/a" element={<UserHome />} />
      <Route path="/" element={<Home />} />
      <Route path="/Blogs" element={<Blog />} />
      <Route path="/instructor" element={<IndexInstructor />} />
      <Route path="/Cart" element={<CartPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/coursedetails" element={<CourseDetails />} />
      <Route path="/Article/1" element={<ArticleById />} />
      <Route path="/Login" element={<LoginPage />} />
      <Route path='/Signup' element={<SignupPage />}/>
      <Route path='/privacy' element={<PrivacyPolicy />}/>
      <Route path="/:slug" element={<UserProfile/>}/>
      <Route path='/update-profile' element={<UpdateProfile/>}/>
      <Route path='/forget-password' element={<Forgetpassword/>}/>
      <Route path='/verfiy-code' element={<VerfiyCode />}/>
      <Route path='/changePassword' element={<ChangePassword />}/>
      <Route path='/Bootstrap' element={<Bootstrap />}/>
      <Route path='/UserCart' element={<UserCart />}/>
      <Route path='/UserBlog' element={<UserBlog />}/>
      <Route path='/Testform' element={<Testform />}/>
      <Route path='/savedcourse' element={<SavedCourses />}/>
      <Route path='/InstructorHome' element={<InstructorHome />}/>
      <Route path='/createcourse' element={<InstructorCreateCourse />}/>
    </Routes>
  </Router>
  );
}

export default App;
