import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LndexCalender from '../../../assets/images/Calendar_Lndex.svg';
import { format } from 'date-fns'; // Import date-fns for date formatting

const DateSelector = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Format the selected date to "dd/MM/yyyy HH.mm"
  const formattedDate = format(selectedDate, 'dd-MM-yyyy HH.mm');

  return (
    <div className='InstructorCreateCourse_form_Section'>
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Start date*</label>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '20px', gap: '10px' }}>
        {/* Date display input */}
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <input
            type="text"
            value={formattedDate} // Use formatted date here
            readOnly
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%', maxWidth: '420px', fontSize: '16px' }}
          />

          {/* Calendar Icon */}
          <img
            src={LndexCalender}
            alt="Calendar Icon"
            style={{ cursor: 'pointer' }}
            onClick={toggleCalendar}
            aria-label="Open calendar"
          />
        </div>

        {/* Conditionally render DatePicker */}
        {isOpen && (
          <div className='Instrector_DateSelector_Container' ref={datePickerRef}>
            <div className='Instrector_DateSelector'>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                timeFormat="HH:mm"
                timeIntervals={15}
                minDate={new Date()} // Disable past dates
                className="custom-datepicker"
                inline
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateSelector;

